import React from "react";
import { connect } from "react-redux";
import "./mapSettings.component.scss";

import CloseIcon from "../../../../../assets/icons/xmark-solid.svg";
import MasterIcon from "../../../../../assets/icons/brain-solid.svg";
import SettingsIcon from "../../../../../assets/icons/gear-solid.svg";
import PlayerList from "../../../../core/components/playerList/playerList.component";
import DisplaySettings from "../../../../core/components/displaySettings/displaySettings.component";
import NetworkSettings from "../../../../core/components/networkSettings/networkSettings.component";
import GameStats from "../../../../core/components/gameStats/gameStats.component";
import BackendInformations from "../../../../core/components/backendInformations/backendInformations.component";

class MapSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      selectedPanel: 1,
    };
  }
  onToggleOpen = () => {
    this.setState({ open: !this.state.open });
  };
  onSelectPanel = (index) => {
    this.setState({
      selectedPanel: this.state.selectedPanel === index ? null : index,
    });
  };
  onCloseGame = () => {
    const action = {
      type: "SELECT_GAME",
      module: "game",
      value: null,
      socket: {
        send: false,
      },
    };

    this.props.dispatch(action);
  };
  render() {
    return (
      <div className={"mapSettings"}>
        {this.state.open ? (
          <>
            <div className="mainBox">
              <div className="header">
                <div
                  className={
                    this.props.gameReducer.selectedGame.gameMaster
                      ? "master connected"
                      : "master"
                  }
                >
                  <img src={MasterIcon} alt="open" />
                </div>
                <div className="title">
                  {this.props.gameReducer.selectedGame.name}
                </div>
                <div className="closeButton" onClick={this.onToggleOpen}>
                  <img src={CloseIcon} alt="open" />
                </div>
              </div>

              <div className="content">
                <div
                  className={
                    this.state.selectedPanel && this.state.selectedPanel === 1
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    this.onSelectPanel(1);
                  }}
                >
                  Characters
                </div>
                <div
                  className={
                    this.state.selectedPanel && this.state.selectedPanel === 2
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    this.onSelectPanel(2);
                  }}
                >
                  Display settings
                </div>
                <div
                  className={
                    this.state.selectedPanel && this.state.selectedPanel === 3
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    this.onSelectPanel(3);
                  }}
                >
                  Network settings
                </div>
                <div
                  className={
                    this.state.selectedPanel && this.state.selectedPanel === 4
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    this.onSelectPanel(4);
                  }}
                >
                  Game stats
                </div>
                <div
                  className={
                    this.state.selectedPanel && this.state.selectedPanel === 5
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    this.onSelectPanel(5);
                  }}
                >
                  Backend informations
                </div>
                <div onClick={this.onCloseGame}>Close game</div>
              </div>
            </div>
            {this.state.selectedPanel && this.state.selectedPanel === 1 && (
              <div className="selectedBox">
                <div className="header">
                  <div className="title">
                    Characters (
                    {this.props.gameReducer.selectedGame.players.length})
                  </div>
                </div>

                <div className="content">
                  <PlayerList />
                </div>
              </div>
            )}
            {this.state.selectedPanel && this.state.selectedPanel === 2 && (
              <div className="selectedBox">
                <div className="header">
                  <div className="title">Display</div>
                </div>

                <div className="content">
                  <DisplaySettings />
                </div>
              </div>
            )}
            {this.state.selectedPanel && this.state.selectedPanel === 3 && (
              <div className="selectedBox">
                <div className="header">
                  <div className="title">Network</div>
                </div>

                <div className="content">
                  <NetworkSettings />
                </div>
              </div>
            )}
            {this.state.selectedPanel && this.state.selectedPanel === 4 && (
              <div className="selectedBox">
                <div className="header">
                  <div className="title">Game</div>
                </div>

                <div className="content">
                  <GameStats />
                </div>
              </div>
            )}
            {this.state.selectedPanel && this.state.selectedPanel === 5 && (
              <div className="selectedBox">
                <div className="header">
                  <div className="title">Backend</div>
                </div>

                <div className="content">
                  <BackendInformations />
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="openButton" onClick={this.onToggleOpen}>
            <img src={SettingsIcon} alt="open" />
          </div>
        )}
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(MapSettings);
