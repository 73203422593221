const initialState = {
  connected: false,
};
function sessionReducer(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "connect":
      console.info("connected");
      nextState = {
        ...state,
        connected: true,
      };
      return nextState || state;
    case "disconnect":
      console.info("disconnect");
      nextState = {
        ...state,
        connected: false,
      };
      return nextState || state;
    default:
      return state;
  }
}
export default sessionReducer;
