import React from "react";
import "./notFoundLayout.layout.scss";

class NotFoundLayout extends React.Component {
  render() {
    return (
      <div className="notFoundLayout">
        <div className="box">
          <h1>404 - Not found</h1>
        </div>
      </div>
    );
  }
}
export default NotFoundLayout;
