import React from "react";
import { connect } from "react-redux";
import "./dashboard.layout.scss";

import Map from "../../core/components/map/map.component";

class DashboardLayout extends React.Component {
  render() {
    return (
      <div className="dashboardLayout">
        <Map />
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(DashboardLayout);
