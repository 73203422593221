const initialState = {
  version: "V1.0.0",
  backendUrl: "http://engine.nemesix.ch",
  backendPort: 9030,
};

function appReducer(state = initialState, action) {
  // let nextState;

  switch (action.type) {
    default:
      return state;
  }
}
export default appReducer;
