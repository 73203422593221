import React from "react";
import { connect } from "react-redux";
import "./gameSelectorItem.component.scss";

import SettingsIcon from "../../../../../assets/icons/gear-solid.svg";
import DeleteIcon from "../../../../../assets/icons/xmark-solid.svg";

class GameSelectorItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      optionsShowed: false,
      confirmDelete: false,
    };
  }
  onToggleShowOptions = () => {
    this.setState({ optionsShowed: !this.state.optionsShowed });
  };
  onDelete = () => {
    this.setState({ confirmDelete: true });
  };
  onConfirmDelete = () => {
    this.setState({ optionsShowed: false, confirmDelete: false });
    const action = {
      type: "DELETE_GAME",
      module: "game",
      value: this.props.item,
      socket: {
        send: true,
      },
    };

    this.props.dispatch(action);
  };
  onConfirmUnDelete = () => {
    this.setState({ optionsShowed: false, confirmDelete: false });
    const action = {
      type: "UNDELETE_GAME",
      module: "game",
      value: this.props.item,
      socket: {
        send: true,
      },
    };

    this.props.dispatch(action);
  };
  render() {
    return (
      <div className="gameSelectorItem">
        <div
          className="title"
          onClick={
            this.props.onSelect
              ? () => {
                  this.props.onSelect(this.props.item);
                }
              : () => {}
          }
        >
          {this.props.item.name}
        </div>
        <div className="actions">
          {this.state.optionsShowed ? (
            <div
              className="action"
              onClick={
                this.state.confirmDelete
                  ? this.props.item.deleted === 1
                    ? this.onConfirmUnDelete
                    : this.onConfirmDelete
                  : this.onDelete
              }
            >
              <img
                className={this.state.confirmDelete ? "image red" : "image"}
                src={DeleteIcon}
                alt=""
              />
            </div>
          ) : (
            <div className="action" onClick={this.onToggleShowOptions}>
              <img className="image" src={SettingsIcon} alt="" />
            </div>
          )}
        </div>
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(GameSelectorItem);
