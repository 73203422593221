import React from "react";
import "./playerListItem.component.scss";
import deadIcon from "../../../../../assets/icons/skull-solid.svg";

class PlayerListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      selectedContent: 1,
    };
  }
  onToggleOpen = () => {
    this.setState({ open: !this.state.open });
  };
  render() {
    var selectedCharacter = this.props.item.selectedCharacterId
      ? this.props.item.characters.find(
          (character) => character.id === this.props.item.selectedCharacterId
        )
      : null;
    return (
      <div
        className={
          !selectedCharacter
            ? "playerListItem selectCharacter"
            : selectedCharacter.dead === 1
            ? "playerListItem isDead"
            : "playerListItem"
        }
      >
        {selectedCharacter && selectedCharacter.dead === 1 && (
          <div className="dead">
            <img src={deadIcon} alt="" />
          </div>
        )}
        <div className="name">
          {selectedCharacter === null
            ? this.props.item.username
            : selectedCharacter !== undefined
            ? selectedCharacter.name
            : "Character not found"}
        </div>
        {selectedCharacter && (
          <>
            <div className="level">lvl {selectedCharacter.level}</div>
            <div className="life">
              {selectedCharacter.life}/{selectedCharacter.maxLife} pv
            </div>
          </>
        )}
        {!this.props.item.selectedCharacterId && (
          <div className="status">Select character</div>
        )}
      </div>
    );
  }
}
export default PlayerListItem;
