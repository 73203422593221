import React from "react";
import { connect } from "react-redux";
import "./gameSelector.component.scss";
import GameSelectorItem from "./gameSelectorItem/gameSelectorItem.component";
import Tabs from "../../../shared/tabs/tabs.component";

class GameSelector extends React.Component {
  constructor(data) {
    super(data);
    const action = {
      type: "list",
      module: "game",
      value: null,
      socket: {
        send: true,
      },
    };

    !this.props.gameReducer.selectedGame && this.props.dispatch(action);
  }
  onSelectGame = (game) => {
    const action = {
      type: "SELECT_GAME",
      module: "game",
      value: game,
      socket: {
        send: true,
      },
    };

    this.props.dispatch(action);
  };
  render() {
    return (
      <div className="gameSelector">
        <div className="mainBox">
          <div className="header">
            <h1>Select game</h1>
          </div>
          <div className="wrapper">
            <Tabs
              items={[
                {
                  label: "In progress",
                  content: (
                    <>
                      {this.props.gameReducer.games
                        .filter(
                          (item) => item.deleted === 0 && item.screen === null
                        )
                        .map((game, index) => (
                          <div key={index}>
                            <GameSelectorItem
                              item={game}
                              onSelect={this.onSelectGame}
                            />
                          </div>
                        ))}
                    </>
                  ),
                },
                {
                  label: "Deleted",
                  content: (
                    <>
                      {this.props.gameReducer.games
                        .filter((item) => item.deleted === 1)
                        .map((game, index) => (
                          <div key={index}>
                            <GameSelectorItem
                              item={game}
                              onSelect={this.onSelectGame}
                            />
                          </div>
                        ))}
                    </>
                  ),
                },
              ]}
            />
            {/* {this.props.gameReducer.games
              .filter((item) => item.deleted === 0)
              .map((game, index) => (
                <div key={index}>
                  <GameSelectorItem item={game} onSelect={this.onSelectGame} />
                </div>
              ))} */}
          </div>
        </div>
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(GameSelector);
