import React from "react";
import "./switch.component.scss";

class Switch extends React.Component {
  render() {
    return (
      <div
        className={this.props.disabled ? "switch disabled" : "switch"}
        onClick={
          this.props.onChange && this.props.onChange !== true
            ? this.props.onChange
            : () => {}
        }
      >
        <div className="value">aa</div>
      </div>
    );
  }
}
export default Switch;
