const initialState = {
  selectedGame: null,
  games: [],
};
function gameReducer(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "UPDATE_GAMES":
      const selectedGame = state.selectedGame
        ? action.value.find((game) => game.id === state.selectedGame.id)
        : state.selectedGame;

      nextState = {
        ...state,
        games: action.value,
        selectedGame: selectedGame,
      };
      return nextState || state;
    case "SELECT_GAME":
      nextState = {
        ...state,
        selectedGame: action.value,
      };
      return nextState || state;
    default:
      return state;
  }
}
export default gameReducer;
