import React from "react";
import { connect } from "react-redux";
import "./playerList.component.scss";

import PlayerListItem from "./playerListItem/playerListItem.component";

class PlayerList extends React.Component {
  constructor(props) {
    super(props);

    //FAKE

    this.players = [
      { name: "Robusto", life: 10, maxLife: 24, level: 3 },
      { name: "Cremor", life: 8, maxLife: 12, level: 2 },
    ];

    this.state = {
      open: true,
      selectedContent: 1,
    };
  }
  onToggleOpen = () => {
    this.setState({ open: !this.state.open });
  };
  render() {
    return (
      <div className={"playerList"}>
        {this.props.gameReducer.selectedGame &&
        this.props.gameReducer.selectedGame.players.length > 0 ? (
          this.props.gameReducer.selectedGame.players.map((player, index) => {
            return <PlayerListItem item={player} key={index} />;
          })
        ) : (
          <div>No players</div>
        )}
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(PlayerList);
