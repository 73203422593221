import React from "react";
import { connect } from "react-redux";
import "./layout.component.scss";
import { Outlet } from "react-router-dom";
import GameSelector from "../gameSelector/gameSelector.component";

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sideBarOpen: true,
    };
  }
  render() {
    return (
      <>
        <div className="layout">
          <div className="contentBox">
            {this.props.sessionReducer.connected ? (
              this.props.gameReducer.selectedGame ? (
                <Outlet />
              ) : (
                <GameSelector />
              )
            ) : (
              <div className="loadingBox">Connection</div>
            )}
          </div>
        </div>
      </>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(Layout);
