import React from "react";
import "./displaySettings.component.scss";

import Switch from "../../../shared/switch/switch.component";

class DisplaySettings extends React.Component {
  render() {
    return (
      <div className="displaySettings">
        <Switch />
      </div>
    );
  }
}
export default DisplaySettings;
