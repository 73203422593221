import React from "react";
import "./tabs.component.scss";

class TabsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTabIndex: this.props.initialTabIndex
        ? this.props.initialTabIndex
        : 0,
    };
  }
  onSelectTab(tabIndex) {
    this.setState({ currentTabIndex: tabIndex });
  }
  render() {
    return (
      <div className="tabsComponent">
        {this.props.items && this.props.items.length > 0 ? (
          <>
            <div className="navigation">
              {this.props.items.map((item, index) => {
                if (typeof item.label === "string") {
                  return (
                    <div
                      key={index}
                      className={
                        index === this.state.currentTabIndex
                          ? "item active"
                          : item.disabled
                          ? "item disabled"
                          : "item"
                      }
                      onClick={() => !item.disabled && this.onSelectTab(index)}
                    >
                      {item.label}
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={index}
                      onClick={() => !item.disabled && this.onSelectTab(index)}
                      className={
                        index === this.state.currentTabIndex
                          ? "active"
                          : item.disabled
                          ? "disabled"
                          : ""
                      }
                    >
                      {item.label}
                    </div>
                  );
                }
              })}
            </div>
            <div className="content">
              {this.props.items[this.state.currentTabIndex].content ? (
                this.props.items[this.state.currentTabIndex].content
              ) : (
                <div>No content</div>
              )}
            </div>
          </>
        ) : (
          <div className="error">No items props or empty</div>
        )}
      </div>
    );
  }
}
export default TabsComponent;
