import React from "react";
import "./backendInformations.component.scss";
import AppService from "../../services/app.service";

class BackendInformations extends React.Component {
  render() {
    return <div className="backendInformations">aaa</div>;
  }
}
export default BackendInformations;
