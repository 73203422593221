import React from "react";
import { connect } from "react-redux";
import "./interface.component.scss";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Layout from "../layout/layout.component";

import NotFoundLayout from "../../../layouts/notFoundLayout/notFoundLayout.layout";
import DashboardLayout from "../../../layouts/dashboard/dashboard.layout";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <NotFoundLayout />,
    children: [
      {
        path: "",
        element: <DashboardLayout />,
      },
    ],
  },
]);

class Interface extends React.Component {
  render() {
    return (
      <div className="interface">
        <RouterProvider router={router} />
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(Interface);
