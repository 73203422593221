import StorageService from "./storage.service";
import { v4 as uuidv4 } from "uuid";
class AppService {
  static async getUid(callback) {
    var uuid = StorageService.get("uuid");
    if (!uuid) {
      uuid = uuidv4();
      StorageService.save("uuid", uuid);
    }

    callback(uuid);
  }
  static getBackendUrl() {
    return "https://engine.nemesix.ch";
  }
  static getBackendPort() {
    return "9030";
  }
}
export default AppService;
