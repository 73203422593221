import React from "react";
import "./map.component.scss";
import { connect } from "react-redux";

import Map1Image from "../../../../assets/maps/map1.webp";
import MapSettings from "./mapSettings/mapSettings.component";

class Map extends React.Component {
  render() {
    return (
      <div className={"map"}>
        {this.props.gameReducer.selectedGame &&
        this.props.gameReducer.selectedGame.currentMap ? (
          <img
            className={"image"}
            src={this.props.gameReducer.selectedGame.currentMap.url}
            alt=""
          />
        ) : (
          <div>no map selected</div>
        )}

        <MapSettings />
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(Map);
