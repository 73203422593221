const wsMiddleWare = (socket) => (store) => (next) => (action) => {
  if (
    action.socket !== undefined &&
    action.socket.send !== undefined &&
    action.socket.send === true
  ) {
    if (action.noSave !== undefined && action.noSave === true) {
      socket.emit("event", action);
    } else {
      socket.emit("event", action);
      next(action);
    }
  } else {
    next(action);
  }
};
export { wsMiddleWare };
