import { combineReducers } from "redux";
import appReducer from "./app.reducer";
import sessionReducer from "./session.reducer";
import gameReducer from "./game.reducer";

export default combineReducers({
  appReducer,
  sessionReducer,
  gameReducer,
});
