// import AsyncStorage from "@react-native-async-storage/async-storage";

class StorageService {
  static save(key, value) {
    localStorage.setItem(key, value);
  }
  static get(key) {
    return localStorage.getItem(key);
  }
}
export default StorageService;
